<template>
  <BForm
    id="credentialsForm"
    :novalidate="true"
    @submit.prevent="onSubmit"
  >
    <BContainer>
      <BRow>
        <!-- Helper -->
        <BCol cols="12">
          <p class="text-small text-muted">
            {{ $t('wizardPage.authStep.helper') }}
          </p>
        </BCol>
        
        <!-- Username -->
        <BCol cols="6">
          <BFormGroup
            id="usernameFormGroup"
            :label="$t('wizardPage.authStep.form.usernameLabel')"
            label-for="usernameInput"
            :description="$t('wizardPage.authStep.form.usernameDescription')"
          >
            <BFormInput
              id="usernameInput"
              v-model="$v.username.$model"
              type="text"
              :placeholder="$t('wizardPage.authStep.form.usernamePlaceholder')"
              autofocus
              :state="validateState('username')"
            />
          </BFormGroup>
        </BCol>
        
        <!-- Password -->
        <BCol cols="6">
          <BFormGroup
            id="passwordFormGroup"
            :label="$t('wizardPage.authStep.form.passwordLabel')"
            label-for="passwordInput"
            :description="$t('wizardPage.authStep.form.passwordDescription')"
          >
            <BFormInput
              id="passwordInput"
              v-model="$v.password.$model"
              type="password"
              :placeholder="$t('wizardPage.authStep.form.passwordPlaceholder')"
              :state="validateState('password')"
            />
          </BFormGroup>
        </BCol>

        <BCol
          cols="12"
          class="mt-4 text-right"
        >
          <!-- Error -->
          <span
            v-if="error"
            class="text-danger mr-4"
          >
            {{ error }}
          </span>
          <!-- Submit -->
          <BButton
            id="submit-btn"
            ref="submitButton"
            type="submit"
            :class="{
              'btn-primary loading shadow-dark': isLoading,
              'btn-dark': $v.$invalid,
              'btn-primary lift': !isLoading && !$v.$invalid
            }"
            variant="null"
            :disabled="isLoading || $v.$invalid"
          >
            {{ $t('wizardPage.authStep.form.submitButtonLabel') }}
          </BButton>
        </BCol>
      </BRow>
    </BContainer>
  </BForm>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

export default {
    name: 'AuthStep',
    mixins: [ validationMixin ],

    props: [
        'step',
    ],

    data () {
        return {
            error: null,
            username: null,
            password: null,
            isLoading: false,
            triedCredentials: [],
        };
    },

    validations: {
        username: {
            required,
            maxLength: maxLength(100),
            minLength: minLength(4),
            knownToBeBad (username) {
                if (this.triedCredentials[btoa(username + ':' + this.password)]) {
                    return false;
                }
                return true;
            },
        },
        password: {
            required,
            maxLength: maxLength(100),
            minLength: minLength(5),
            knownToBeBad (password) {
                if (this.triedCredentials[btoa(this.username + ':' + password)]) {
                    return false;
                }
                return true;
            },
        },
    },

    watch: {
        username: function () {
            this.error = false;
        },
        password: function () {
            this.error = false;
        },
    },

    mounted () {
        this.$emit('setTitle', this.$t('wizardPage.authStep.pageTitle'));
    },
    
    methods: {
        validateState (name) {
            const { $dirty, $error } = this.$v[name];
            return $dirty ? !$error : null;
        },
        onSubmit () {
            const self = this;
            if (!self.triedCredentials[btoa(self.username + ':' + self.password)]) {
                self.isLoading = true;
                self.$http.post('authentication', {
                    username: self.username,
                    password: self.password,
                })
                    .then((response) => {
                        self.isLoading = false;
                        self.error = false;
                        self.$emit('setToken', response.data.token);
                        self.$emit('setUser', response.data.user);
                        self.$emit('setStep', 'release');
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            self.error = self.$t('wizardPage.authStep.errors.invalid');
                            self.triedCredentials[btoa(self.username + ':' + self.password)] = true;
                        } else {
                            self.error = self.$t('wizardPage.authStep.errors.unknown');
                        }
                        self.isLoading = false;
                    });
            } else {
                self.error = self.$t('wizardPage.authStep.errors.invalid');
            }
        },
    },
};
</script>