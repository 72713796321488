<template>
  <BContainer>
    <BRow>
      <!-- Helper -->
      <BCol cols="12">
        <p class="text-small text-muted">
          {{ $t('wizardPage.completedStep.helper') }}
        </p>
      </BCol>

      <!-- Actions -->
      <BCol
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        class="mb-6"
      >
        <BButton
          id="restart-btn"
          ref="restartButton"
          class="btn-outline-dark lift"
          variant="block"
          @click="onRestart"
        >
          <i class="syms sym-redo-alt mr-2" />
          {{ $t('wizardPage.completedStep.form.restartButtonLabel') }}
        </BButton>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        class="mb-6"
      >
        <BButton
          id="logout-btn"
          ref="logoutButton"
          class="btn-outline-primary lift"
          variant="block"
          @click="onLogout"
        >
          <i class="syms sym-door-open mr-2" />
          {{ $t('wizardPage.completedStep.form.logoutButtonLabel') }}
        </BButton>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
export default {
    name: 'CompletedStep',

    methods: {
        onRestart () {
            this.$emit('setStep', 'release');
        },
        onLogout () {
            this.$emit('doLogout');
        },
    },
};
</script>

<style lang="scss">
</style>