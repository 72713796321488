<template>
  <footer>
    <BContainer>
      <BRow>
        <BCol
          cols="12"
          class="py-6"
        >
          <div class="d-flex align-items-center font-size-xs text-gray-500">
            <i class="syms sym-info-circle sym-lg mr-2" />
            <span>{{ $t('footer.disclaimer') }}</span>
          </div>
        </BCol>
      </BRow>
    </BContainer>
  </footer>
</template>

<script>
export default {
    name: 'AppFooter',
};
</script>