/* eslint-disable vue/no-v-html */
<template>
  <BContainer>
    <BRow>
      <!-- Helper -->
      <BCol cols="12">
        <p class="text-small text-muted">
          {{ $t('wizardPage.pageStep.helper') }}
        </p>
      </BCol>

      <!-- Error -->
      <BCol
        v-if="error"
        cols="12"
      >
        <BAlert
          show
          variant="danger"
        >
          <i class="syms sym-exclamation-circle mr-2" />
          {{ error }}
        </BAlert>
      </BCol>
    </BRow>
    <BRow class="my-5">
      <!-- Actions -->
      <BCol
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        class="mb-6"
      >
        <BButton
          id="cancel-btn"
          ref="cancelButton"
          :class="{
            'lift': !isLoading,
          }"
          class="btn-outline-dark"
          :disabled="isLoading"
          variant="block"
          @click="onCancel"
        >
          <i class="syms sym-arrow-left mr-2" />
          {{ $t('wizardPage.pageStep.form.cancelButtonLabel') }}
        </BButton>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        class="mb-6"
      >
        <BButton
          id="preview-btn"
          ref="previewButton"
          :class="{
            'btn-primary loading shadow-dark': action === 'preview' && isLoading,
            'btn-primary': action === 'preview',
            'btn-outline-primary': action !== 'preview' && isLoading,
            'btn-outline-primary lift': action !== 'preview' && !isLoading,
          }"
          :disabled="isLoading"
          variant="block"
          @click="onPreview"
        >
          <i class="syms sym-glasses mr-2" />
          {{ $t('wizardPage.pageStep.form.previewButtonLabel') }}
        </BButton>
      </BCol>
      <BCol
        cols="12"
        sm="12"
        md="12"
        lg="6"
        xl="6"
        class="mb-6"
      >
        <BButton
          id="publish-btn"
          ref="publishButton"
          :class="{
            'btn-success loading shadow-dark': action === 'publish' && isLoading,
            'btn-success': action === 'publish',
            'btn-outline-success': action !== 'publish' && isLoading,
            'btn-outline-success lift': action !== 'publish' && !isLoading,
          }"
          :disabled="isLoading"
          variant="block"
          @click="onPublish"
        >
          <i class="syms sym-cloud-upload-alt mr-2" />
          <span v-if="release.pageId">
            {{ $t('wizardPage.pageStep.form.updateButtonLabel') }}
          </span>
          <span v-else>
            {{ $t('wizardPage.pageStep.form.publishButtonLabel') }}
          </span>
        </BButton>
        <!-- Existing page warning -->
        <div
          v-if="release.pageId"
          class="d-flex align-items-center justify-content-center mt-2 text-muted font-size-xs"
        >
          <i class="syms sym-exclamation-circle mr-2" />
          <span>{{ $t('wizardPage.pageStep.form.existingPageWarning') }}</span>
        </div>
      </BCol>

      <!-- Preview modal -->
      <BModal
        id="preview-modal"
        ref="previewModal"
        :title="releaseNotes.title"
        data-backdrop="dark"
        :ok-disabled="true"
        :cancel-disabled="true"
        size="xl"
        centered
        scrollable
      >
        <template v-slot:default>
          <div v-html="previewBody" />
        </template>
        <template v-slot:modal-footer>
          &nbsp;
        </template>
      </BModal>
    </BRow>
  </BContainer>
</template>

<script>
export default {
    name: 'PageStep',

    props: [
        'release',
    ],

    data () {
        return {
            action: null,
            error: null,
            isLoading: false,
            releaseNotes: [],
            isPreviewOpen: false,
        };
    },

    computed: {
        previewBody: function () {
            if (this.releaseNotes && this.releaseNotes.body) {
                let html = this.releaseNotes.body;
                html = html.replaceAll('<ac:structured-macro ac:', '<acstructuredmacro ');
                html = html.replaceAll('</ac:structured-macro', '</acstructuredmacro');
                html = html.replaceAll('<ac:macro ac:', '<acmacro ');
                html = html.replaceAll('</ac:macro', '</acmacro');
                html = html.replaceAll('<ac:parameter ac:', '<acparameter ');
                html = html.replaceAll('</ac:parameter', '</acparameter');
                const parser = new DOMParser();
                const document = parser.parseFromString(html, 'text/html');
          
                const tocItem = document.querySelector(`acstructuredmacro[name="toc"]`);
                if (tocItem) {
                    tocItem.remove();
                }

                const labelItem = document.querySelector(`acmacro[name="add-label"]`);
                if (labelItem) {
                    labelItem.remove();
                }

                const altTitleItem = document.querySelector(`acstructuredmacro[name="alternative-title"]`);
                if (altTitleItem) {
                    altTitleItem.remove();
                }

                const statusBadges = document.querySelectorAll(`acstructuredmacro[name="status"]`);
                statusBadges.forEach((badge) => {

                    let bClass = 'badge-danger';
                    switch (badge.querySelector(`acparameter[name="colour"]`).innerText) {
                      case 'Red':
                        bClass = 'badge-danger';
                        break;
                      case 'Yellow':
                        bClass = 'badge-warning';
                        break;
                      case 'Green':
                        bClass = 'badge-success';
                        break;
                      case 'Blue':
                        bClass = 'badge-info';
                        break;
                      case 'Grey':
                        bClass = 'badge-dark';
                        break;
                      default:
                        bClass = 'badge-danger';
                    }

                    const bText = badge.querySelector(`acparameter[name="title"]`)
                      ? badge.querySelector(`acparameter[name="title"]`).innerText
                      : null;
                    
                    badge.outerHTML = `<span class="badge badge-pill ${bClass}">${bText}</span>`;
                });

                return document.querySelector(`body`).innerHTML;
            }
            return null;
        },
    },

    mounted () {
        this.$emit('setTitle', this.$t('wizardPage.pageStep.pageTitle'));
    },

    methods: {
        onCancel () {
            this.$emit('setStep', 'release');
        },
        onPreview () {
            const self = this;
            self.action = 'preview';
            self.isLoading = true;
            self.$http.get('page', {
                params: {
                    version: self.release.version,
                },
            })
                .then((response) => {
                    self.isLoading = false;
                    self.error = false;
                    self.releaseNotes = {
                        title: response.data.page.title,
                        body: response.data.page.body,
                    };
                    self.openPreviewModal();
                    self.action = null;
                })
                .catch(() => {
                    self.isLoading = false;
                    self.error = self.$t('wizardPage.pageStep.errors.unknown');
                    self.action = null;
                });
        },
        onPublish () {
            const self = this;
            self.action = 'publish';
            self.isLoading = true;
            self.$http.post('page', {
                version: self.release.version,
                overwrite: true,
            })
                .then(() => {
                    self.isLoading = false;
                    self.error = false;
                    self.action = null;
                    self.$emit('setStep', 'completed');
                })
                .catch(() => {
                    self.isLoading = false;
                    self.error = self.$t('wizardPage.pageStep.errors.unknown');
                    self.action = null;
                });
        },
        openPreviewModal () {
            this.$bvModal.show('preview-modal');
        },
        closePreviewModal () {
            this.$bvModal.hide('preview-modal');
        },
    },
};
</script>

<style lang="scss">
  #preview-modal {
    .modal-footer {
      display: none;
    }
  }
</style>