const messages = {
    en: {
        app: {
            name: 'Carus Release Notes Publisher',
            name_wordmark: 'Carus',
            name_addon: 'Release Notes Publisher',
        },
        wizardPage: {
            authStep: {
                pageTitle: 'Sign in',
                wizardLabel: 'Sign in',
                heading: 'Sign in to your account',
                helper: 'Sign in using the same credentials you use for Jira and Confluence:',
                form: {
                    usernameLabel: 'Username',
                    usernamePlaceholder: '',
                    usernameDescription: '',
                    passwordLabel: 'Password',
                    passwordPlaceholder: '',
                    passwordDescription: '',
                    submitButtonLabel: 'Sign in',
                },
                errors: {
                    invalid: 'Incorrect username or password',
                    unknown: 'Unknown error',
                },
            },
            releaseStep: {
                pageTitle: 'Find version',
                wizardLabel: 'Find release',
                heading: 'Find version',
                helper: 'Enter a version:',
                form: {
                    versionLabel: 'Version',
                    versionPlaceholder: 'E.g. "6.1.0"',
                    versionDescription: '',
                    submitButtonLabel: 'Continue',
                },
                errors: {
                    invalid: 'Could not find version',
                    unknown: 'Unknown error',
                },
            },
            pageStep: {
                pageTitle: 'Publish or preview',
                wizardLabel: 'Preview & Publish',
                heading: 'Publish or preview',
                helper: 'Publish or preview the release notes:',
                form: {
                    cancelButtonLabel: 'Cancel',
                    previewButtonLabel: 'Preview',
                    publishButtonLabel: 'Publish',
                    updateButtonLabel: 'Update',
                    existingPageWarning: 'Release notes already exist',
                },
                errors: {
                    unknown: 'Unknown error',
                },
            },
            completedStep: {
                pageTitle: 'Done!',
                wizardLabel: 'Done!',
                heading: 'Done!',
                helper: 'Alright, release notes successfully published. What\'s next?',
                form: {
                    restartButtonLabel: 'Do another release',
                    logoutButtonLabel: 'Sign out',
                },
            },
        },
        errors: {
            unknown: {
                title: 'Uh oh,',
                helper: 'Sorry, something\'s not right. The backend returned {code} ({text}) - that\'s all we know. Try again later, yeah?'
            },
        },
        footer: {
            disclaimer: 'This tool publishes release notes to Confluence, that clients can then view. Use with caution.',
        },
        loading: 'Loading...',
        wait: 'Please wait',
    },
};

export default messages;
