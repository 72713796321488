<template>
  <div
    v-if="isLoading"
    class="d-flex vh-100 w-100 justify-content-center align-items-center"
  >
    <BSpinner />
    <p class="my-0 ml-3">
      {{ $t('wait') }}
    </p>
  </div>
  <div v-else>
    <AppHeader
      :user="user"
      @doLogout="onLogout"
    />
    <main class="py-8 bg-light border-bottom border-gray-300 shadow-sm">
      <Wizard
        v-if="step"
        :step="step"
      />
      <BContainer fluid>
        <div v-if="step">
          <AuthStep
            v-if="step === 'auth'"
            @setTitle="onSetTitle"
            @setUser="onSetUser"
            @setToken="onSetToken"
            @setStep="onSetStep"
          />
          <ReleaseStep
            v-if="step === 'release'"
            @setTitle="onSetTitle"
            @setRelease="onSetRelease"
            @setStep="onSetStep"
          />
          <PageStep
            v-if="step === 'page'"
            :release="release"
            @setPage="onSetPage"
            @setTitle="onSetTitle"
            @setStep="onSetStep"
          />
          <CompletedStep
            v-if="step === 'completed'"
            @setTitle="onSetTitle"
            @setStep="onSetStep"
            @doLogout="onLogout"
          />
        </div>
      </BContainer>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import Wizard from '@/pages/WizardPage/Wizard';
import AuthStep from '@/pages/WizardPage/AuthStep';
import ReleaseStep from '@/pages/WizardPage/ReleaseStep';
import PageStep from '@/pages/WizardPage/PageStep';
import CompletedStep from '@/pages/WizardPage/CompletedStep';

export default {
    name: 'WizardPage',

    components: {
        'AppHeader': AppHeader,
        'AppFooter': AppFooter,
        'Wizard': Wizard,
        'AuthStep': AuthStep,
        'ReleaseStep': ReleaseStep,
        'PageStep': PageStep,
        'CompletedStep': CompletedStep,
    },

    data () {
        return {
            step: null,
            user: [],
            token: null,
            release: [],
            page: [],
            isLoading: false,
        };
    },

    mounted () {
        this.getUser();
    },

    methods: {
        getUser () {
            const self = this;
            self.isLoading = true;
            self.$http.post('authentication')
                .then((response) => {
                    self.isLoading = false;
                    self.onSetUser({
                        'username': response.data.user.username,
                        'name': response.data.user.name,
                        //'password': self.password,
                        'token': response.data.token,
                    });
                    self.step = 'release';
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        self.step = 'auth';
                    } else {
                        alert(self.$t('errors.unknown.helper', { code: error.response.status }));
                    }
                    self.isLoading = false;
                });
        },
        onSetUser (value) {
            this.user = value;
        },
        onSetToken (value) {
            this.token = value;
        },
        onSetStep (value) {
            this.step = value;
        },
        onSetRelease (value) {
            this.release = value;
        },
        onSetPage (value) {
            this.page = value;
        },
        onSetTitle (value) {
            this.$emit('changeDocumentTitle', value);
        },
        onLogout () {
            const self = this;
            self.user = [];
            self.token = null;
            self.isLoading = true;
            self.$http.delete('authentication')
                .then(() => {
                    self.isLoading = false;
                    self.step = 'auth';
                })
                .catch(() => {
                    location.reload();
                });
        },
    },
};
</script>