<template>
  <BNavbar
    id="app-header"
    toggleable="lg"
    type="dark"
    variant="primary-desat"
    class="border-bottom border-gray-300 shadow-sm"
  >
    <BContainer>
      <!-- Logo -->
      <BNavbarBrand>
        <div class="logo logo-sm">
          <div class="wordmark">
            <span class="sr-only">
              {{ $t("app.name_wordmark") }}
            </span>
          </div>
          <div class="append d-none d-md-table-cell">
            {{ $t("app.name_addon") }}
          </div>
        </div>
      </BNavbarBrand>
      
      <!-- Main menu -->
      <BNavbarToggle
        v-if="user && user.username"
        target="nav-mainmenu-collapse"
      />
      <BCollapse
        v-if="user && user.username"
        id="nav-mainmenu-collapse"
        is-nav
      >
        <BNavbarToggle target="nav-mainmenu-collapse">
          <i class="syms sym-times" />
        </BNavbarToggle>

        <!-- User menu -->
        <BNavbarNav class="ml-auto">
          <BNavItemDropdown
            v-if="user"
            right
          >
            <template v-slot:button-content>
              {{ user.name }}
            </template>
            <BDropdownItem @click="logout">
              Sign out
            </BDropdownItem>
          </BNavItemDropdown>
        </BNavbarNav>
      </BCollapse>
    </BContainer>
  </BNavbar>
</template>

<script>
export default {
    name: 'AppHeader',

    props: [
        'user',
    ],

    methods: {
        logout () {
            this.$emit('doLogout');
        },
    },
};
</script>

<style lang="scss">
  .navbar-nav.ml-auto {
    .nav-link.dropdown-toggle {
      padding-right: 0;
    }
  }
</style>