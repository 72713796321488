import Vue from 'vue';
import router from './routes';
import i18n from './locale';
import httpClient from './httpClient';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import App from './App';

Vue.prototype.$http = httpClient;

Vue.use(Vuelidate);
Vue.use(BootstrapVue);

new Vue({
    el: '#app',
    render: h => h(App),
    router,
    i18n,
});
