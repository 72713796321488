<template>
  <BForm
    id="versionForm"
    :novalidate="true"
    @submit.prevent="onSubmit"
  >
    <BContainer>
      <BRow>
        <!-- Helper -->
        <BCol cols="12">
          <p class="text-small text-muted">
            {{ $t('wizardPage.releaseStep.helper') }}
          </p>
        </BCol>
        
        <!-- Version field -->
        <BCol cols="12">
          <BFormGroup
            id="versionFormGroup"
            :label="$t('wizardPage.releaseStep.form.versionLabel')"
            label-for="usernameInput"
            :description="$t('wizardPage.releaseStep.form.versionDescription')"
          >
            <BFormInput
              id="versionInput"
              v-model="$v.version.$model"
              type="text"
              :placeholder="$t('wizardPage.releaseStep.form.versionPlaceholder')"
              autofocus
              :state="validateState('version')"
            />
          </BFormGroup>
        </BCol>

        <BCol
          cols="12"
          class="mt-4 text-right"
        >
          <!-- Error -->
          <span
            v-if="error"
            class="text-danger mr-4"
          >
            {{ error }}
          </span>
          <!-- Submit -->
          <BButton
            id="submit-btn"
            ref="submitButton"
            type="submit"
            :class="{
              'btn-primary loading shadow-dark': isLoading,
              'btn-dark': $v.$invalid,
              'btn-primary lift': !isLoading && !$v.$invalid
            }"
            variant="null"
            :disabled="isLoading || $v.$invalid"
          >
            {{ $t('wizardPage.releaseStep.form.submitButtonLabel') }}
          </BButton>
        </BCol>
      </BRow>
    </BContainer>
  </BForm>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

export default {
    name: 'ReleaseStep',
    mixins: [ validationMixin ],

    data () {
        return {
            version: null,
            error: null,
            isLoading: false,
            triedVersions: [],
        };
    },

    validations: {
        version: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(10),
            knownToBeBad (version) {
                if (this.triedVersions[version]) {
                    return false;
                }
                return true;
            },
        },
    },

    watch: {
        version: function () {
            this.error = false;
        },
    },

    mounted () {
        this.$emit('setTitle', this.$t('wizardPage.releaseStep.pageTitle'));
    },

    methods: {
        validateState (name) {
            const { $dirty, $error } = this.$v[name];
            return $dirty ? !$error : null;
        },
        onSubmit () {
            const self = this;
            if (!self.triedVersions[self.version]) {
                self.isLoading = true;
                self.$http.get('release', {
                    params: {
                        version: self.version,
                    }
                })
                    .then((response) => {
                        self.isLoading = false;
                        self.error = false;
                        self.$emit('setRelease', response.data.release);
                        self.$emit('setStep', 'page');
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            self.error = self.$t('wizardPage.releaseStep.errors.invalid');
                            self.triedVersions[self.version] = true;
                        } else {
                            self.error = self.$t('wizardPage.releaseStep.errors.unknown');
                        }
                        self.isLoading = false;
                    });
            } else {
                self.error = self.$t('wizardPage.releaseStep.errors.invalid');
            }
        },
    },
};
</script>

<style lang="scss">
</style>