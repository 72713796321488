var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BContainer',[_c('BRow',[_c('BCol',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-small text-muted"},[_vm._v(" "+_vm._s(_vm.$t('wizardPage.pageStep.helper'))+" ")])]),(_vm.error)?_c('BCol',{attrs:{"cols":"12"}},[_c('BAlert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"syms sym-exclamation-circle mr-2"}),_vm._v(" "+_vm._s(_vm.error)+" ")])],1):_vm._e()],1),_c('BRow',{staticClass:"my-5"},[_c('BCol',{staticClass:"mb-6",attrs:{"cols":"12","sm":"6","md":"6","lg":"3","xl":"3"}},[_c('BButton',{ref:"cancelButton",staticClass:"btn-outline-dark",class:{
          'lift': !_vm.isLoading,
        },attrs:{"id":"cancel-btn","disabled":_vm.isLoading,"variant":"block"},on:{"click":_vm.onCancel}},[_c('i',{staticClass:"syms sym-arrow-left mr-2"}),_vm._v(" "+_vm._s(_vm.$t('wizardPage.pageStep.form.cancelButtonLabel'))+" ")])],1),_c('BCol',{staticClass:"mb-6",attrs:{"cols":"12","sm":"6","md":"6","lg":"3","xl":"3"}},[_c('BButton',{ref:"previewButton",class:{
          'btn-primary loading shadow-dark': _vm.action === 'preview' && _vm.isLoading,
          'btn-primary': _vm.action === 'preview',
          'btn-outline-primary': _vm.action !== 'preview' && _vm.isLoading,
          'btn-outline-primary lift': _vm.action !== 'preview' && !_vm.isLoading,
        },attrs:{"id":"preview-btn","disabled":_vm.isLoading,"variant":"block"},on:{"click":_vm.onPreview}},[_c('i',{staticClass:"syms sym-glasses mr-2"}),_vm._v(" "+_vm._s(_vm.$t('wizardPage.pageStep.form.previewButtonLabel'))+" ")])],1),_c('BCol',{staticClass:"mb-6",attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('BButton',{ref:"publishButton",class:{
          'btn-success loading shadow-dark': _vm.action === 'publish' && _vm.isLoading,
          'btn-success': _vm.action === 'publish',
          'btn-outline-success': _vm.action !== 'publish' && _vm.isLoading,
          'btn-outline-success lift': _vm.action !== 'publish' && !_vm.isLoading,
        },attrs:{"id":"publish-btn","disabled":_vm.isLoading,"variant":"block"},on:{"click":_vm.onPublish}},[_c('i',{staticClass:"syms sym-cloud-upload-alt mr-2"}),(_vm.release.pageId)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('wizardPage.pageStep.form.updateButtonLabel'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('wizardPage.pageStep.form.publishButtonLabel'))+" ")])]),(_vm.release.pageId)?_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-2 text-muted font-size-xs"},[_c('i',{staticClass:"syms sym-exclamation-circle mr-2"}),_c('span',[_vm._v(_vm._s(_vm.$t('wizardPage.pageStep.form.existingPageWarning')))])]):_vm._e()],1),_c('BModal',{ref:"previewModal",attrs:{"id":"preview-modal","title":_vm.releaseNotes.title,"data-backdrop":"dark","ok-disabled":true,"cancel-disabled":true,"size":"xl","centered":"","scrollable":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.previewBody)}})]},proxy:true},{key:"modal-footer",fn:function(){return [_vm._v("   ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }