<template>
  <div id="app-wrapper">
    <router-view @changeDocumentTitle="setDocumentTitle" />
  </div>
</template>

<script>
export default {
    name: 'App',

    methods: {
        setDocumentTitle (title) {
            document.title = title
                ? `${title} - ${this.$t('app.name')}`
                : this.$t('app.name');
        },
    },
};
</script>

<style lang="scss">
  body {
    background-color: var(--white);
  }
</style>