<template>
  <BContainer class="pb-8 user-select-none">
    <BRow>
      <BCol cols="12">
        <ol class="wizard">
          <li
            class="wizard-item"
            :class="{
              'active': step === 'auth'
            }"
          >
            {{ $t("wizardPage.authStep.wizardLabel") }}
          </li>
          <li
            class="wizard-item"
            :class="{
              'active': step === 'release'
            }"
          >
            {{ $t("wizardPage.releaseStep.wizardLabel") }}
          </li>
          <li
            class="wizard-item"
            :class="{
              'active': step === 'page'
            }"
          >
            {{ $t("wizardPage.pageStep.wizardLabel") }}
          </li>
        </ol>
      </BCol>
    </BRow>      
  </BContainer>
</template>

<script>
export default {
    name: 'Wizard',

    props: [
        'step',
    ],
};
</script>