import WizardPage from '@/pages/WizardPage';

const routes = [
    {
        path: '/',
        name: 'index',
        component: WizardPage,
    },
];

export default routes;
